<template>
  <v-app>
    <v-container fill-height class="ios-fill-height">
      <v-row no-gutters>
        <v-col cols="12" align="center" align-content="center" justify="center">
          <img src="@/assets/logo-tickets.png" />
        </v-col>
        <v-col class="login" cols="12" align="center" align-content="center" justify="center">
          <router-view />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "GateLayout"
};
</script>

<style scoped lang="scss">
.ios-fill-height img {
  height: 200px;
  margin: 0 auto 20px;
}
.login {
  max-width: 480px;
  margin: 0 auto;
}
</style>
